<template>
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      max-width="500px"

    >
      <v-card>
        <v-card-text style="height: 370px; overflow-y:hidden;" class>
          <div class="ma-6 pa-0 text-center">
            <img
              src="@/assets/img/5910366.png"
              width="150px"
              height="150px"
              alt="One Box"
            />
          </div>
          <div class="text-center">
            <h2>{{ $t("conditions") }}</h2>
          </div>
          <div class="text-center">
            <br />
            <span style="font-size: 20px;">
                {{ $t("textconditions") }}
            </span>
          </div>
          <br />
          <div class="text-center">

          <v-btn color="red" outlined @click="$emit('closedialog')">{{
            $t("changeName.close")
          }}</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </template>
  <script>
  import { mapState, mapGetters } from "vuex";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import "sweetalert2/src/sweetalert2.scss";
import { h } from "@vue/runtime-core";
  export default {
    props: ["show"],
    computed: {
        ...mapState(["username", "authorize", "account_active", "color"]),
        ...mapState({ processloader: "loading" }),
        ...mapGetters([
            "dataUsername",
            "dataAuthorize",
            "dataAccountActive",
            "dataAccesstoken",
            "dataBusinessProfile",
            "dataCitizenProfile",
            "dataDepartmentAccessId",
            "dataAccountId",
        ]),
        shower: {
            get() {
                if (this.show === true) {
                }
                return this.show;
            },
        },
        resolutionScreen() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return 220;
                case "sm":
                    return 400;
                case "md":
                    return 500;
                case "lg":
                    return 600;
                case "xl":
                    return 800;
            }
        },
        // style อักษร header ของ mobile
        headerPage() {
            return ("color:" +
                this.color.theme +
                ";" +
                "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;");
        },
        // style ปุ่ม ของ mobile
        btnAction() {
            return "font-size: 16px; font-weight: lighter;";
        },
        // style อักษรใน dialog ของ mobile
        titleText() {
            return "color:black; font-size: 14px; line-height: 28px;";
        },
        // ความกว้างของ dialog ของ ipad และ mobile
        maxWidthOnMobile() {
            if (this.resolutionScreen >= 400) {
                return 600;
            }
            else {
                return 346;
            }
        },
    },
    components: { h }
};
  </script>